<script setup lang="ts">
const { t } = useT();
const { open } = useNlcModals();
const { depositInfoData, timerWheelDisabled, locizeWheelOfWins, imgWheelOfWins } = useDepositStreakData({
	immediate: false
});

const timerActive = computed(() => depositInfoData.value.secondsToResetStreakCounter);

const buttonName = computed(() => {
	if (timerActive.value) {
		return timerWheelDisabled.value;
	}
	if (depositInfoData.value.canWheel) {
		return t("Spin");
	}
	return t("Buy");
});
const handleWheelOfWinsClick = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: "wheel_of_wins",
		location: "reward_cabinet"
	});
	if (depositInfoData.value.secondsToResetStreakCounter) {
		navigateTo("/wheel-of-wins/");
		return;
	}
	if (depositInfoData.value.canWheel) {
		open("LazyOModalWheelOfWinsPlayWheel");
		return;
	}

	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};
</script>
<template>
	<div @click="handleWheelOfWinsClick">
		<div class="icon">
			<NuxtImg
				:src="`${imgWheelOfWins}/wheel/mini-wheel-percent.png`"
				width="80"
				height="82"
				loading="lazy"
				quality="100"
				format="webp"
				alt="wheel of wins"
			/>
		</div>
		<div class="content">
			<AText class="color-neutral-100" :size="16" :modifiers="['bold']" as="div">
				{{ t(`${locizeWheelOfWins}.Name`) }}
			</AText>
			<LazyMWheelOfWinsStepsProgressBar class="wheel-progress" />
			<AText class="progress-text color-neutral-60" :size="10" as="div">
				<i18n-t :keypath="`${locizeWheelOfWins}.rewards.text.underProgress`">
					<template #count>
						{{ `${depositInfoData?.currentCountDeposit}/${depositInfoData?.maxNumberOfDeposit}` }}
					</template>
				</i18n-t>
			</AText>
		</div>
		<AButton
			:variant="timerActive ? 'secondary' : 'primary'"
			:modifiers="[timerActive ? 'disabled' : '']"
			class="btn"
			size="s"
		>
			<AText :size="12" :modifiers="['uppercase']">{{ buttonName }}</AText>
		</AButton>
	</div>
</template>
<style lang="scss" scoped>
.icon {
	display: flex;
	align-items: center;

	img {
		width: 52px;
		height: 52px;
	}
}

.btn {
	--a-button-secondary-background: var(--custom-9);
	--a-button-secondary-disabled-background: var(--custom-9);
	--a-button-secondary-disabled-color: var(--neutral-100);
}

.wheel-progress.progress {
	&:deep() {
		background: none;
		padding: 8px 0 4px 0;

		.step {
			padding: 5px 9px;
		}
	}
}
</style>
